<template>
  <div class="media-app">
    <header>
      <ul class="tabs">
        <li :class="MediaData.type===1?'active':''"
            style="margin: 0 68px"
            @click="changeTab(1)">
          照片
        </li>
        <li :class="MediaData.type===2?'active':''"
            @click="changeTab(2)">视频</li>
      </ul>
      <div>
        <Upload :data="MediaData"
                :headers='{"token": token}'
                name="file"
                :action="MediaUrl"
                :format="mediaFormat"
                :on-format-error="handleFormatError"
                :on-success="addMediaSuccess"
                :on-error="addMediaError"
                class="tab-btn"
                :show-upload-list="false">
          上传
        </Upload>
        <div class="tab-btn"
             @click.stop="fodlerModal = true">新建文件夹</div>
      </div>
    </header>
    <div v-show="folderArr.length>1"
         class="back-on-folder"
         @click="backOnFolder()">
      <Icon type="ios-arrow-back" />返回
    </div>
    <div v-if="MediaData.type === 1 && folders.length === 0 && imgdata.length === 0 "
         class="noMedia">暂无数据</div>
    <div v-if="MediaData.type === 2 && folders.length === 0 && videodata.length === 0 "
         class="noMedia">暂无数据</div>
    <div class="fold-list">
      <ul>
        <li v-for="(data) in folders"
            :key="data.file_id"
            @click="MediaData.folderId=data.file_id;folderArr.unshift(data.file_id);getFolder()">
          <Icon type="ios-folder"
                size="60" />
          <p class="folder-name">{{ data.file_name }}</p>
          <Button type="info"
                  size="small"
                  style="right:35px"
                  shape="circle"
                  @click.stop="selectFolderId = data.file_id;selectFolderName = data.file_name;fodlerModal = true"
                  icon="md-create">
          </Button>
          <Button type="error"
                  size="small"
                  shape="circle"
                  @click.stop="selectFolderId = data.file_id;selectFolderName = data.file_name;delFolderModal = true;"
                  icon="md-close">
          </Button>
        </li>
      </ul>
    </div>

    <!-- 照片 -->
    <div v-if="MediaData.type === 1">
      <div class="media-list">
        <ul>
          <viewer :images="imgdata">
            <li v-for="(data) in imgdata"
                :key="data.file_id">
              <img :src="data.path" />
              <p class="media-date">{{ data.file_name }}</p>
              <Button type="error"
                      size="small"
                      shape="circle"
                      @click.stop="delMedia(data.file_id)"
                      icon="md-close">
              </Button>
            </li>
          </viewer>
        </ul>
      </div>
    </div>
    <!-- 视频 -->
    <div v-if="MediaData.type === 2">
      <div class="media-list">
        <ul>
          <li v-for="(data) in videodata"
              :key="data.file_id"
              @click="playVideo(data.path)">
            <video :src="data.path"></video>
            <p class="media-date">{{ data.file_name }}</p>
            <Button type="error"
                    size="small"
                    shape="circle"
                    @click.stop="delMedia(data.file_id)"
                    icon="md-close">
            </Button>
          </li>
        </ul>
      </div>
    </div>
    <!-- <Page :total="total"
          show-total
          v-if="total>16"
          :current="parseInt(page)"
          :page-size="limit"
          style="text-align: center"
          @on-change="changePage" /> -->

    <!-- 视频窗口 -->
    <article v-show="videoModal">
      <Icon type="md-close"
            color="white"
            class="close"
            size="20"
            @click="closeVideoModal()" />
      <video controls
             ref="video"
             :src="bigVideo"
             autoplay>
        <!-- <source :src="bigVideo" type="video/mp4" />
        您的浏览器不支持Video标签。 -->
      </video>
    </article>
    <Modal v-model="delModel"
           :loading="true"
           @on-ok="delOK"
           width="300">
      <p style="text-align: center; font-size: 18px; margin: 20px 0 10px">
        确定删除？
      </p>
    </Modal>
    <Modal v-model="fodlerModal"
           :title="selectFolderId?'文件夹重命名':'新建文件夹'"
           :loading="true"
           @on-ok="editFodler"
           @on-cancel="selectFolderId='';selectFolderName=''"
           width="300">
      <Input v-model="selectFolderName"
             maxlength="10"
             show-word-limit
             placeholder="请输入文件名称" />
    </Modal>
    <Modal v-model="delFolderModal"
           :loading="true"
           @on-ok="delFolderOK"
           @on-cancel="selectFolderId=''"
           title="确认删除"
           width="400">
      <p style="text-align: center; font-size: 16px; margin: 10px 0">
        确定删除文件夹：{{selectFolderName}}？<br />
        <!-- <span style="font-size: 14px; ">其中的媒体文件会一并删除</span> -->
      </p>
    </Modal>
  </div>
</template>

<script>
import Api from '@/utils/api.js';

export default {
  data() {
    return {
      teamID: -1,
      MediaUrl: '',
      page: 1,
      total: 0,
      //   showPage: false,
      mediaFormat: ['jpg', 'png', 'jpeg'],
      imgdata: [],
      videodata: [],
      MediaData: {
        // client: 'web',
        type: 1,
        folderId: 0,
      },
      folders: [], // 文件夹列表
      isFolder: true, // 文件夹模式
      fodlerModal: false,
      delFolderModal: false,
      selectFolderName: '',
      imgModal: false,
      selectFolderId: '', // 选中编辑的文件夹id
      folderArr: [0],
      bigIndex: 0,
      bigImgList: [],
      bigImgTime: '',

      videoModal: false,
      bigVideo: '',
      delID: '',
      delModel: false,
      limit: 16,
    };
  },
  created() {
    this.token = sessionStorage.getItem('token');
    // console.log('改NM的傻逼东西,劳资真想给你两拳!');
    // console.log('余延你家死光了是吧,整天死了妈一样逼逼赖赖,就你麻痹的会来事!');
  },
  mounted() {
    this.MediaUrl = Api.addUserMedia();
    this.userInfo = JSON.parse(sessionStorage.getItem('user_info'));
    if (sessionStorage.getItem('page')) {
      this.page = sessionStorage.getItem('page');
    }
    if (sessionStorage.getItem('team_id') === null) {
      this.teamID = -1;
      this.folderArr = [0];
      this.getFolder();
    } else {
      this.teamID = Number(sessionStorage.getItem('team_id'));
      this.folderArr = [0];
      this.getFolder();
    }
    this.$_bus.$off('change_Team').$on('change_Team', (value) => {
      this.teamID = value;
      this.folderArr = [0];
      //   this.changePage(1);
      this.getFolder();
    });
  },
  methods: {
    // 切换tab栏
    changeTab(n) {
      this.MediaData.type = n;
      if (n === 1) {
        this.mediaFormat = ['jpg', 'png', 'jpeg'];
      } else {
        this.mediaFormat = ['mp4'];
      }
      this.page = 1;
      this.folderArr = [0];
      this.MediaData.folderId = 0;
      this.getFolder();
    },
    // 回到文件夹
    backOnFolder() {
      this.folderArr.shift();
      this.MediaData.folderId = this.folderArr[0].toString();
      this.getFolder();
    },
    // 获取文件夹
    getFolder() {
      console.log(this.folderArr);
      this.$post(Api.getUserMedia(), {
        team_id: this.teamID.toString(),
        type: this.MediaData.type.toString(),
        folder_id: this.folderArr[0].toString(),
      }).then((res) => {
        if (res.code === 1) {
          this.folders = [];
          this.imgdata = [];
          this.videodata = [];
          res.data.forEach((ele) => {
            if (ele.path == '') {
              this.folders.push(ele);
            } else if (ele.type == 1) {
              this.imgdata.push(ele);
            } else if (ele.type == 2) {
              this.videodata.push(ele);
            }
          });
        }
      });
    },
    // 新建文件夹
    editFodler() {
      // 判断新建或编辑
      if (this.selectFolderId) {
        this.$post(Api.updateFolderName(), {
          folder_name: this.selectFolderName,
          folder_id: this.selectFolderId.toString(),
        }).then((res) => {
          if (res.code === 1) {
            this.$Message.success(res.msg_customer);
            this.selectFolderId = '';
            this.fodlerModal = false;
            this.getFolder();
          } else {
            this.selectFolderId = '';
            this.fodlerModal = false;
            this.$Message.error(res.msg_customer);
          }
        });
      } else {
        this.$post(Api.createUserFolder(), {
          folder_name: this.selectFolderName,
          team_id:
            this.teamID == -1
              ? this.userInfo.team_id.toString()
              : this.teamID.toString(),
          type: this.MediaData.type.toString(),
          parent_id: this.folderArr[0].toString(),
        }).then((res) => {
          if (res.code === 1) {
            this.$Message.success(res.msg_customer);
            this.fodlerModal = false;
            this.getFolder();
          } else {
            this.fodlerModal = false;
            this.$Message.error(res.msg_customer);
          }
        });
      }
    },
    // 删除文件夹
    delFolderOK() {
      this.$post(Api.delUserFolder(), {
        folder_id: this.selectFolderId.toString(),
      }).then((res) => {
        if (res.code === 1) {
          this.$Message.success(res.msg_customer);
          this.delFolderModal = false;
          this.selectFolderId = '';
          this.getFolder();
        } else {
          this.selectFolderId = '';
          this.delFolderModal = false;
          this.$Message.error(res.msg_customer);
        }
      });
    },
    // 获取资源
    getMedia() {
      //   this.showPage = false;
      this.$post(Api.getUserMedia(), {
        // team_id: this.teamID,
        type: this.MediaData.type.toString(),
        folder_id: this.folderArr[0],

        // page: this.page,
        // limit: this.limit,
        // client: 'web',
      }).then((res) => {
        if (res.code === 1) {
          this.total = res.data.total;
          //   if (this.total > 30) {
          //     setTimeout(() => {
          //       this.showPage = true;
          //     }, 100);
          //   }
          if (this.MediaData.type === 1) {
            // 图片
            this.imgdata = res.data;
          } else if (this.MediaData.type === 2) {
            this.videodata = res.data;
          }
        }
      });
    },
    // 翻页
    changePage(p) {
      this.page = p;
      this.backOnFolder();
    },

    // 关闭弹窗
    closeImgModal() {
      //   document.documentElement.style.overflowY = 'auto';
      this.imgModal = false;
      this.bigIndex = 0;
      this.bigImgList = [];
      this.bigImgTime = '';
    },
    /**
     * 视频
     */
    playVideo(path) {
      document.documentElement.style.overflowY = 'hidden';
      this.bigVideo = path;
      this.videoModal = true;
    },
    closeVideoModal() {
      document.documentElement.style.overflowY = 'auto';
      this.$refs.video.pause();
      this.bigVideo = '';
      this.videoModal = false;
    },
    /**
     * 上传
     */
    // 上传格式错误
    handleFormatError(file) {
      let tips = '';
      if (this.MediaData.type === 1) {
        tips = '请选择jpg或png格式文件';
      } else {
        tips = '请选择mp4格式文件';
      }
      this.$Notice.warning({
        title: '文件格式不正确',
        desc: tips,
      });
    },
    // 上传失败
    addMediaError(error) {
      this.$Message.error(error);
      this.getFolder();
    },
    // 上传成功
    addMediaSuccess(response) {
      if (response.code === 1) {
        this.$Message.info('上传成功');
        this.getFolder();
      } else {
        this.$Message.error(response.msg_customer);
      }
    },
    // 删除
    delMedia(id) {
      if (this.userInfo.is_manager != 1) {
        this.$Message.info('飞手没有删除权限');
      } else {
        this.delID = id;
        this.delModel = true;
      }
    },
    delOK() {
      this.$post(Api.userMediaDel(), {
        client: 'web',
        media_id: this.delID,
      }).then((res) => {
        this.$Message.info(res.msg_customer);
        if (res.code === 1) {
          this.delModel = false;
          this.getFolder();
          this.delID = '';
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.media-app {
  width: 100%;
  height: 100%;
  // position: relative;
  header {
    background-color: #464d54;
    color: #fff;
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    padding-left: 20px;
    padding-right: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tabs {
      display: inline-flex;
      li {
        padding: 0 16px;
        border-radius: 18px;
        cursor: pointer;
        height: 29px;
        line-height: 29px;
      }
      .active {
        background-color: #fff;
        color: #464d54;
      }
    }
    .tab-btn {
      display: inline-block;
      margin-left: 20px;
      width: 100px;
      height: 30px;
      line-height: 30px;
      cursor: pointer;
      background-color: #2196f3;
      text-align: center;
      font-size: 14px;
    }
  }
  .noMedia {
    font-size: 16px;
    text-align: center;
    margin: 50px 0;
  }
  .back-on-folder {
    background-color: #1d1d1d;
    color: #fff;
    width: 100px;
    padding: 3px 0;
    font-size: 16px;
    text-align: center;
    margin: 10px;
    cursor: pointer;
  }
  .fold-list {
    margin: 20px 0 15px 28px;
    color: #fff;
    p {
      font-size: 14px;
      margin-bottom: 10px;
    }
    ul {
      li {
        display: inline-block;
        background-color: #464d54;
        width: 180px;
        height: 120px;
        overflow: hidden;
        margin-right: 25px;
        padding-top: 10px;
        border-radius: 10px;
        cursor: pointer;
        position: relative;
        text-align: center;
        // border: 1px solid #515a6e;
        button {
          display: none;
          position: absolute;
          bottom: 5px;
          right: 5px;
          opacity: 0;
          transition: all 0.3s linear;
        }
      }
      li:hover button {
        opacity: 1;
        display: block;
      }
    }
  }
  // 图片
  .media-list {
    margin: 20px 0 15px 28px;
    p {
      font-size: 12px;
      margin-bottom: 10px;
    }
    ul {
      li {
        display: inline-block;
        width: 200px;
        max-height: 264px;
        overflow: hidden;
        margin-right: 25px;
        cursor: pointer;
        position: relative;
        img,
        video {
          margin: 0 auto;
          height: 234px;
        }
        button {
          display: none;
          position: absolute;
          bottom: 20px;
          right: 5px;
          opacity: 0;
          transition: all 0.3s linear;
        }
      }
      li:hover button {
        opacity: 1;
        display: block;
      }
    }
  }
  // 视频
  .video-list {
    margin: 20px 0 15px 28px;
    p {
      font-size: 12px;
      margin-bottom: 10px;
    }
    ul {
      li {
        display: inline-block;
        width: 150px;
        height: 150px;
        overflow: hidden;
        margin-right: 25px;
        cursor: pointer;
        position: relative;
        img {
          height: 150px;
          margin: 0 auto;
        }
        button {
          position: absolute;
          bottom: -35px;
          right: 5px;
          transition: bottom 0.3s linear;
        }
      }
      li:hover button {
        bottom: 5px;
      }
    }
  }

  .media-date {
    padding: 10px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
// 弹窗

article {
  position: fixed;
  z-index: 1200;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(23, 23, 23, 0.84);
  display: flex;
  justify-content: center;
  align-items: center;
  .close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    z-index: 99;
    padding: 5px;
    background-color: #000;
    border-radius: 50%;
  }
  video {
    max-height: 100%;
    max-width: 100%;
  }
}
</style>

